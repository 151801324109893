const C_PLACE_POST_POLICY = {
  MANAGER: 'manager',
  MANGER_MEMBER: 'manager_member',
  TEAM: 'team',
  COMPANY: 'company',
  EMAIL: 'email',
  CREATOR: 'creators',
  EVERYONE: 'everyone',
};

export default C_PLACE_POST_POLICY;
