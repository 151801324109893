const UploadType = {
  AUDIO: 'audio',
  FILE: 'file',
  GIF: 'gif',
  IMAGE: 'image',
  PLACE_PIC: 'place_pic',
  PROFILE_PIC: 'profile_pic',
  VIDEO: 'video',
};

export default UploadType;
