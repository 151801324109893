const ReportTypeLabel = {
  AddPost: 'Posts',
  AddEmail: 'Emails',
  AttachmentSize: 'Attachment Size',
  AttachmentCount: 'Attachment Count',
  AddComment: 'Comments',
  Login: 'Login',
  SessionRecall: 'Session Recall',
  AllRequests: 'All Requests',
  dataIn: 'data In',
  dataOut: 'data Out',
  AccountPost: 'Account Post',
  PlacePost: 'Place Post',
  AccountComment: 'Account Comment',
  PlaceComment: 'Place Comment',
  processTime: 'Process Time',
  taskAdd: 'New Tasks',
  taskComment: 'Comments on Tasks',
  taskCompleted: 'Completed Tasks',
  AccountTaskAdd: 'Account Created Tasks',
  AccountTaskComment: 'Account Comments on Tasks',
  AccountTaskCommpleted: 'Account Completed Tasks',
  AccountTaskAssigned: 'Account Assigned Tasks',
};

export default ReportTypeLabel;
