const C_USER_SEARCH_AREA = {
  ACCOUNTS: '_for_search',
  ADMIN: '_for_admin',
  ADD: '_for_add',
  INVITE: '_for_invite',
  MENTION: '_for_mention',
  TASK_MENTION: '_for_task_mention',
};

export default C_USER_SEARCH_AREA;
